import { CalendarMonthOutlined } from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog';
import InlineImage from '../../../components/InlineEdit/InlineImage';
import withAlert from '../../../hoc/withAlert'
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import NotificationForm from '../components/NotificationForm';
import notificationServices from '../notificationServices';


const NotificationListPage = ({AppAlert}) => {

    const [notificationList,setNotificationList] = useState(null);
    
    const getNotificationList = () =>{
        notificationServices.get().then((res)=>{        
            setNotificationList(res.data);
        }).catch(error=>{
        })
    }

    useEffect(()=>{
        getNotificationList();
    },[]);


    const remove = (id) => {
        notificationServices.remove(id).then(res=>{
            AppAlert(res.msg)
            getNotificationList();
        }).catch(error=>{
            AppAlert(error.msg);
        })

    }

    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-12" >
                        <h3 className="float-start">Notification List</h3>
                        <span className="float-end"><NotificationForm  AppAlert={AppAlert} refreshList={getNotificationList} /></span> 
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        
                            <div className="row">
                                {notificationList &&
                                    notificationList.map(notification=>
                                        
                                            <div key={notification.id} className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        {notification.title}
                                                        <span className="float-end">
                                                            <NotificationForm data={notification} AppAlert={AppAlert} refreshList={getNotificationList} />
                                                            <ConfirmDialog id={notification.id} confirm={remove} >Are you sure you want to remove this ad</ConfirmDialog>
                                                        </span> 
                                                        </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p>{notification.details}</p>
                                                                <p>
                                                                    <CalendarMonthOutlined/>{moment(notification.startDate).format("ll")}
                                                                    {notification.startDate !== notification.endDate && <span> - {moment(notification.endDate).format("ll")}</span> }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                }
                            </div>
                        
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(NotificationListPage);