import { ArrowRight, CalendarMonth, Person, WorkOutlined } from '@mui/icons-material'
import { LinearProgress, Slide, Zoom } from '@mui/material'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import ConfirmDialog from '../../../components/ConfirmDialog'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import kioskServices from '../kioskServices';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

const  KioskTokenDetailsPage = ({AppAlert})  => {
    let navigate = useNavigate();
    const [tokenDetailList,setTokenDetailList] = useState(null)
    const [savingInProgress, setSavingStatus] = useState(false);

    
    const getCustomerTokenList = ()=>{
        let payload = {customerToken : JSON.parse(localStorage.customerToken)};
        kioskServices.getCustomerTokenList(payload).then(res=>{
            setTokenDetailList(null);
            if(res.data.length > 0){
                setTokenDetailList(res.data)
            }
            

        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            
        })
    }   

    

    useEffect(()=>{
        if (localStorage.getItem("customerToken") !== null) {

            getCustomerTokenList();
        }
    },[])

    const confirmCancel = (tokenHash) =>{
        setSavingStatus(true);
        kioskServices.cancelCheckin({tokenHash:tokenHash}).then(res=>{
            AppAlert("Success");
            getCustomerTokenList();
            //localStorage.removeItem("tokenDetails");
            setTimeout(()=>{
                //navigate("/customer-portal/1")
            },2000)
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const getDelay = (i) =>{
        let delay = (i+1)*100+"ms"
        return {transitionDelay : delay}
    }


    const printTokenData = ({tokenDetails}) =>{
        return(
            <div className="row">
                <div className="clol-12">
                    <h1>Hello</h1>
                </div>
            </div>
        )
    }


    const printToken = (tokenDetails) =>{
        var mywindow = window.open( "", "new div", "height=400,width=600" );
        mywindow.document.write( "<html><head><title></title>" );
        mywindow.document.write( "</head><body style='font-size:50px'>" );

        mywindow.document.write('<div >');
        mywindow.document.write(`<center><h1>${tokenDetails.tokenPrefix}-${tokenDetails.tokenNo}</h1></center>`);
        mywindow.document.write(`<p>Visitor Name : ${tokenDetails.firstName} - ${tokenDetails.lastName}</p>`);
        mywindow.document.write(`<p>Date : ${tokenDetails.checkInDate}</p>`);
        mywindow.document.write(`<p>Work : ${tokenDetails.queueTitle} - ${tokenDetails.serviceTitle}</p>`);
        mywindow.document.write(`</div>`);

        mywindow.document.write( "</body></html>" );
        mywindow.print();
        return true;
    }


    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        pageStyle:"@page { size: 2.5in 2.5in; margin: 0; padding:0 }, @media print { @page {  size: 2.5in 2.5in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }"
        //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"



        
    });

    return (
        <AppLayout>
            
                <div className="container">
                    {tokenDetailList && tokenDetailList.length > 0 &&
                        <>
                            {tokenDetailList.map((tokenDetails,index) => 
                                
                                    tokenDetails.status === 1 &&

                                        <Zoom in={true} style={getDelay(index)} key={tokenDetails.id}>
                                            <div className="card mb-2" >
                                                <div className="card-body" >
                                                        
                                                    <div className="row mt-2">
                                                        
                                                            <div className="col-xs-12 text-center">
                                                                <div className="card bg-primary text-white text-center p-2 mb-2">
                                                                    <h4 className="mt-2">{tokenDetails.tokenPrefix}-{tokenDetails.tokenNo}</h4>
                                                                </div>
                                                            </div>
                                                        
                                                        <div className="col-xs-12">
                                                            
                                                                <h6><Person/> : {tokenDetails.firstName}-{tokenDetails.lastName}</h6>
                                                                <h6><CalendarMonth/> : {tokenDetails.checkInDate}</h6>
                                                                <h6><WorkOutlined/> : {tokenDetails.queueTitle} <ArrowRight/> {tokenDetails.serviceTitle}</h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="card-body" style={{display:"none"}} id={"token"+tokenDetails.id}>
                                                        
                                                    <div style={{padding:"5px"}} ref={printComponentRef} className="text-center">
                                                        <h1>{tokenDetails.tokenPrefix}-{tokenDetails.tokenNo}</h1>
                                                        <table style={{fontSize:20}}>
                                                            <tr>
                                                                <th>Name: </th>
                                                                <td>{tokenDetails.firstName} {tokenDetails.lastName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Date: </th>
                                                                <td>{ moment(tokenDetails.checkInDate).format("ll")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">{tokenDetails.queueTitle}</td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                </div>

                                                <div className="card-footer">
                                                    {!savingInProgress &&
                                                        <>
                                                        {/* <span className="float-end"><ConfirmDialog title="Cancel My Token" id={tokenDetails.tokenHash} confirm={confirmCancel} >Are you Sure you want to cancel your token</ConfirmDialog></span> */}
                                                        { 
                                                            process.env.REACT_APP_BASE_URL === "https://hcbaran-api.gtoken.in/" ?
                                                            <span className="float-end me-2"><button type="button" onClick={()=>handlePrint()} className="btn btn-success" >Print</button></span>
                                                            :
                                                            <span className="float-end me-2"><button type="button" onClick={()=>printToken(tokenDetails)} className="btn btn-success" >Print token</button></span>
                                                        }
                                                        </>
                                                    }
                                                    {savingInProgress && <LinearProgress/>}
                                                </div>
                                            </div>
                                        </Zoom>
                                    
                                    
                                
                            )}

                            <div className="card p-2">
                                <Link className="btn btn-success" to="/kiosk/portal/1">Book Another Token</Link>
                            </div>
                        </>
                    }
                    {!tokenDetailList && 
                        <div className="card p-3 text-center">
                            <h6>No Token Booked</h6>
                            <Link className="btn btn-success" to="/kiosk/portal/1">Book My Token</Link>
                        </div>
                    }
                </div>
            
        </AppLayout>
    )
}

export default withAlert(KioskTokenDetailsPage);