import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LinearProgress, Slide, StepContent } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { Step, StepLabel, Stepper } from '@mui/material';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import OTPInput, { ResendOTP } from "otp-input-react";


import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import kioskServices from '../kioskServices';
import { SocketContext } from '../../../context/socket';

function KioskCheckinFormPageWitoutPhone({AppAlert}) {
    let navigate = useNavigate();
    let {businessId, queueId,serviceId } = useParams();
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);


    const initialValues = {
        firstName:"",
        phone:"",
        businessId:businessId,
        queueId:queueId,
        serviceId:serviceId
    }

    /*
    const validationRule = Yup.object({
        phone:Yup.string().required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Invalid Phone Number')
        .max(10, 'Invalid Phone Number'),
    });
    */

    
    const socket = useContext(SocketContext);

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        kioskServices.kioskCheckin(values).then(res=>{

            socket.emit("send_new_token",{message:"new token received"})

            AppAlert(res.msg);
            localStorage.setItem('customerToken',JSON.stringify(res.customerToken));
            navigate("/kiosk/token-details");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }


    return (
        <AppLayout>
        {true && 
            <div className="container">
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div className="">
                        <div className="p-2">
                            <Formik
                                initialValues={formValues || initialValues}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                            >
                                {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                            <Form>
                                                    
                                                        <div className="row p-2">
                                                            <div className="col-md-12"> 
                                                                
                                                                                    <div className="row mt-4">
                                                                                        <div className="text-center">
                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                label="Your Name" name="firstName"
                                                                                                className="col-md-12 mt-4"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        {  /* JSON.stringify(formik.values) */ }
                                                        </div>
                
                                                    {!savingInProgress && 
                                                        <>
                                                            <div className="col-md-12">
                                                                    <button className="btn btn-success float-end" type="submit">Check In </button>
                                                            </div>
                                                        </>
                                                    }

                                                    {savingInProgress && <LinearProgress/>}

                                                    
                                                
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                </Slide>
            </div>
        }
        </AppLayout>
    )
}

export default withAlert(KioskCheckinFormPageWitoutPhone)