import api from "../../api/api";

const getCheckinQueueList = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("token/checkin/list/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const callCheckinVisitor = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("token/checkin/call-visitor/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const callOnHoldVisitor = (id,tokenId) =>{
    return new Promise((resolve, reject)=>{
        api.get("token/checkin/call-on-hold-visitor/"+id+"/"+tokenId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateCheckinStatus = (data) =>{
    return new Promise((resolve, reject)=>{
        api.post("token/checkin/update-status",data).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const transferCheckinQueue = (data) =>{
    return new Promise((resolve, reject)=>{
        api.post("/transfer/checkin-queue",data).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getUserNotificationList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/user-notification").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const checkinServices = {
    getCheckinQueueList,
    callCheckinVisitor,
    callOnHoldVisitor,
    updateCheckinStatus,
    transferCheckinQueue,
    getUserNotificationList,
}

export default checkinServices;