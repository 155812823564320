import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { startSession } from "./ams/redux/authStore";
import AppRoutes from "./ams/routes/AppRoutes";

function App() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authUser = useSelector(state => state.auth);
  const userId = authUser.id;

  useEffect(()=>{ 

    if(localStorage.portalUrl === undefined){
        navigate("/auth/select-portal");
    } else {
        if(localStorage.authUser){
            userId === undefined && dispatch(startSession())
        } else {
            navigate("/auth/login");
        }
    }
  },[])

  //[dispatch,navigate,userId,location]

  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
