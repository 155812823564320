import React, { useState,useCallback, useContext } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import queueServices from '../../Queue/queueServices';
import checkinServices from '../checkinServices';
import { SocketContext } from '../../../context/socket';
import { SwapHoriz } from '@mui/icons-material';


function TransferToken({visitor,refreshList}) {

    const [businessId,setBusinessId] = useState(1);
    const [dialogStatus, setDialogStatus] = useState(false); 

    const [queueList,setQueueList] = useState(null);
    const [loading,setLoadingStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [newTokenDetails, setNewTokenDetails] = useState(null); 

    const socket = useContext(SocketContext);


    const getQueueList = useCallback(() =>{
        setLoadingStatus(true);
        queueServices.getQueueListByBusinessId(businessId).then(res=>{
            setQueueList(res.data);
        }).catch(error=>{
            //AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[businessId]);


    const transferQueue = (queueId) =>{
        
        
        let postData = {
            queueId:queueId,
            tokenId:visitor.id
        }

        
        setSavingStatus(true);
        checkinServices.transferCheckinQueue(postData).then(res=>{
            //console.log("here in success");
            //console.log(res);
            socket.emit("send_close_token",{message:"Token transfered"})
            setNewTokenDetails(res.newTokenDetails);
            setDialogStatus(false);
            setNewTokenDialogStatus(true);
            
        }).catch(error=>{
            alert(error.msg);
            //AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })

    }


    const handleClose = () =>{
        setDialogStatus(false);
    }

    const showDialog = () =>{
        setDialogStatus(true);
        getQueueList();
    }

    
    const [newTokenDialogStatus, setNewTokenDialogStatus] = useState(false); 
    const handleNeTokenDialogClose = () =>{
        setNewTokenDialogStatus(false);
        refreshList();
    }

    

    return (
        <>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Transfer Token</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <h3>Private / Senior Queue</h3>
                        </div>
                        <div className="col-12">
                            {queueList && queueList.map(queue =>
                                <span key={queue.id}>{queue.status === 1 && queue.public === 0 && <button type="button" className="btn btn-lg btn-primary m-1" onClick={()=>{transferQueue(queue.id)}} >{queue.title}</button> }</span>
                            )}
                        </div>

                        <div className="col-12">
                            <h3>Public Queue</h3>
                        </div>
                        <div className="col-12">
                            {queueList && queueList.map(queue =>
                                <span key={queue.id}>{queue.status=== 1 && queue.public === 1 && <button type="button" className="btn btn-lg btn-primary m-2" onClick={()=>{transferQueue(queue.id)}} >{queue.title}</button> }</span>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={newTokenDialogStatus}
                keepMounted
                onClose={handleNeTokenDialogClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>New Token</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            {newTokenDetails && 
                                <div className="row mt-2 mb-2">
                                    <div className="col-6">
                                        <h4> Token # : </h4>
                                    </div>
                                    <div className="col-6">
                                        <span className="bg-primary text-white rounded shadow  p-2" style={{fontSize:26}}>{newTokenDetails.prefix} - {newTokenDetails.tokenNo} </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-danger" onClick={handleNeTokenDialogClose} >Close</button>
                </DialogActions>
            </Dialog>
            <button type="button" className="btn btn-primary" onClick={showDialog} ><SwapHoriz/> Transfer Token</button>
        </>
    )
}

export default TransferToken