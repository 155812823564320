import api from "../../api/api";


const get = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/notification").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/notification/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const save = (data) =>{
    return new Promise((resolve, reject)=>{
        api.post("/notification",data).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/notification",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const remove = (id) =>{
    return new Promise((resolve, reject)=>{
        api.delete("/notification/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const notificationServices = {
    get,
    getById,
    save,
    updateInline,
    remove,
}

export default notificationServices;