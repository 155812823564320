import React,{useEffect} from 'react'

function AnnounceToken({displayList,getCounterNumber,announceTokenId, voiceIndex}) {

    const prefixLang =(prefix) =>{
        return prefix.split('').join(', ');
    }


    const speak = (text) => {
        console.log("here", text);
        var utterance = new SpeechSynthesisUtterance();
        utterance.text = text;
        utterance.voice = speechSynthesis.getVoices()[voiceIndex];
        speechSynthesis.speak(utterance);
    }

    useEffect(()=>{        
        if(displayList ){
            displayList.forEach(token=>{
                if(token.status === 2 && token.id === announceTokenId) {
                    let newAnnouncement = "";
                    
                    newAnnouncement = "Token Number "+prefixLang(token.tokenPrefix)+"! "+token.tokenNo+"! Counter Number "+getCounterNumber("users",token.counterUserId) + " से संपर्क करे";
                    
                    speak(newAnnouncement);
                }
            })
        }
    },[displayList])
    

}

export default AnnounceToken