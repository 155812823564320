import { Alert, Snackbar, Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';
import ExpandableCard from '../../../components/ExpandableCard'
import AppLayout from '../../../layout/components/AppLayout'
import QueueLayout from '../components/QueueLayout'
import queueServices from '../queueServices';
import queueUpdateServices from '../queueUpdateServices';


import { LinearProgress } from "@mui/material";
import { Formik, Form, isEmptyArray } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import MasterServices from '../../../services/masterServices';
import useSystemMasterList from '../../../hooks/useSystemMasterList';



export default function AppointmentConfigPage() {

    let { id } = useParams();

    const [loading,setLoadingStatus] = useState(false);
    const [queueConfig,setQueueConfig] = useState(null);

    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");
    
    const [masters,getMasterLabel] = useSystemMasterList(['queue-type']);

    const getQueueConfig = useCallback(()=>{
        setLoadingStatus(true);
        queueServices.getQueueConfig(id).then(res=>{
            updateData(res.data.queueDetails)
            setQueueConfig(res.data);
            
        }).catch(error=>{
            alertHandler(error.msg,"error")
        }).finally(()=>{
            setLoadingStatus(false);
        })

    },[id]);


    const alertHandler = (message,messageType="success") =>{
        setSnackStatus(true);
        setSnackType(messageType);
        setSnackMessage(message);
    }

    const initialValues = {
        queueId: "",
        businessId:"",
        title:"",
        queueType:"",
        maxFutureDays:"",
        otpEmail:0,
        otpPhone:1,
        prefix:"",
        stations:"",
        avgProcessTime:"",
        details:"",
        status:"",
        public:"",
        displaystatus:1,
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        queueUpdateServices.updateAppointConfiguration(values).then(res => {
            getQueueConfig();
            alertHandler(res.msg);
        }).catch(error => {
            alertHandler(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        title:Yup.string().required("Required"),
        maxFutureDays:Yup.string().required("Required"),
        prefix:Yup.string().required("Required"),
        stations:Yup.string().required("Required"),
        avgProcessTime:Yup.string().required("Required"),
        details:Yup.string().required("Required"),
        
    });

    useEffect(()=>{
        getQueueConfig();
    },[])


    const updateData = (data)=>{
        let autoFill = {
            id:data.id,
            businessId:data.businessId,
            title:data.title,
            queueType:data.queueType,
            maxFutureDays:data.maxFutureDays,
            otpEmail:data.otpEmail,
            otpPhone:data.otpPhone,
            prefix:data.prefix,
            stations:data.stations,
            avgProcessTime:data.avgProcessTime,
            details:data.details,
            status:data.status,
            public:data.public,
            displayStatus:data.displayStatus,
        }
        setFormValues(autoFill);
    }

    return (
        <AppLayout>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>
            {loading && <CommonLoadingSkeleton/> }
            {queueConfig && queueConfig.businessHolidays &&
                <QueueLayout queueDetails={queueConfig.queueDetails}>
                    <ExpandableCard title={"Appointment Config"} defaultOpen={true}>
                        <div className="card-body">



                            <Formik
                                initialValues={formValues || initialValues}
                                validationSchema={validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                            >
                                {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                            <Form>
                                                    <div className="row p-2">
                                                        <div className="col-md-12">

                                                            <div className="row ">
                                                                
                                                                <div className="col-md-6">
                                                                <FormikControl control="switch" formik={formik} 
                                                                    label="Enable / Disable Appointment" name="status" 
                                                                    className="col-md-6 col-xs-12 p-2" 
                                                                />
                                                                </div>


                                                                <div className="col-md-6">
                                                                <FormikControl control="switch" formik={formik} 
                                                                    label="Open For public" name="public" 
                                                                    className="col-md-6 col-xs-12 p-2" 
                                                                />
                                                                </div>


                                                                {masters && 
                                                                    <FormikControl control="autoselect" formik={formik} 
                                                                        label="Queue Type" name="queueType"  
                                                                        options={masters['queue-type']} multiple={false} 
                                                                        className="col-md-6 col-xs-6 p-2"
                                                                    />
                                                                }

                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                    label="Queue Display Name" name="title" 
                                                                    className="col-md-6 col-xs-12 p-2 mt-2" 
                                                                />

                                                            </div>


                                                            <div className="row mt-3">

                                                                <FormikControl control="input" formik={formik} type="number" 
                                                                    label="Max Appointment Future Days" name="maxFutureDays" 
                                                                    className="col-md-4 col-xs-12 p-2" 
                                                                />

                                                                <FormikControl control="input" formik={formik} type="number" 
                                                                    label="Avg Process Time (Minutes)" name="avgProcessTime" 
                                                                    className="col-md-4 col-xs-12 p-2" 
                                                                />

                                                                <div className="col-md-4 col-xs-12 p-2" >
                                                                <FormikControl control="input" formik={formik} type="number" 
                                                                    label="Service Stations" name="stations" 
                                                                    
                                                                />
                                                                <p className="text-success small">Number of customer you can server in parallel *</p>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                {/*
                                                                <FormikControl control="switch" formik={formik} 
                                                                    label="Send Otp On Email" name="otpEmail" 
                                                                    className="col-md-4 col-xs-12 p-2" 
                                                                />
                                                                */}
                                                                
                                                                <div className="col-md-6 col-xs-12 p-2" >
                                                                    <FormikControl control="switch" formik={formik} 
                                                                        label="Phone Number Required" name="otpPhone" 
                                                                    />
                                                                </div>

                                                                <div className="col-md-6 col-xs-12 p-2" >
                                                                    <FormikControl control="switch" formik={formik} 
                                                                        label="Show on Display Board" name="displayStatus" 
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="row">

                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                    label="Details" name="details" 
                                                                    className="col-md-4 col-xs-12 p-2" 
                                                                />

                                                                <FormikControl control="input" formik={formik} type="text" 
                                                                    label="Token Prefix" name="prefix" 
                                                                    className="col-md-4 col-xs-12 p-2" 
                                                                />
                                                            </div>
            
                                                        </div>
                                                    </div>
            
                                                    <div className="row">
                                                    { /*  JSON.stringify(formik.values) */}
                                                    </div>
            
                                                    
                                                    {savingInProgress && <div className="row"><LinearProgress/></div> }
                                                    {!savingInProgress && 
                                                        <>
                                                        <button className="btn btn-success" type="submit">Save</button>
                                                        </>
                                                    }
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                            
                        </div>
                    </ExpandableCard>
            
                </QueueLayout>
            }
      </AppLayout>
    )
}

