import { AccountCircle, Announcement, CalendarMonthOutlined, Campaign, Chat, Dvr, FollowTheSigns, Home,  Label,  ListAlt,  ManageAccounts, MoreVert, Notifications, PhoneAndroid, Settings, Store, Tablet, TransferWithinAStation, Tv, WorkOutlined} from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom'


export default function Header() {
    
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;

    const navigate = useNavigate(); 
    

    useEffect(()=>{
        if(authUser.verified === 0){
          navigate("/auth/profile");
        }

        if(authUser.role === 3){
            navigate("/checkin/queue");
        }

        if(authUser.role === 4){
            navigate("/checkin/display");
        }

        if(authUser.role === 5){
            navigate("/kiosk/portal/1");
        }

        if(authUser.role === 6){
            navigate("/checkin/mini-display");
        }

        

    },[authUser])


    return (
        <>  { userId && 
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <span className="navbar-brand" >
                        <img src="/global-logo.png" height="50" alt="" />
                    </span>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"18px"}}>
                                { authUser.role <= 3 && <>
                                    
                                    { authUser.role <= 2 && 
                                        <li className="nav-item text-center">
                                            <NavLink className="nav-link" aria-current="page" to="/"> <Home/>Dashboard </NavLink>
                                        </li>
                                    }

                                    { authUser.role <= 2 && 
                                    <li className="nav-item dropdown text-center">
                                        <a className="nav-link" href="!#" id="communicationControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <Chat/> Comm. </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="communicationControllDropdown">
                                            <li><NavLink className="dropdown-item" to="/news/list"> <Announcement/>News</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/notification/list"> <Campaign/> Notification</NavLink></li>
                                            
                                        </ul>
                                    </li>
                                    }

                                    { authUser.role <= 2 && 
                                    <li className="nav-item dropdown text-center">
                                        <a className="nav-link" href="!#" id="displayControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <Tv/> Display </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dislayControllDropdown">
                                            <li><NavLink className="dropdown-item" to="/checkin/display"> <Tv/> Display</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/checkin/mini-display"> <Tablet/> Mini Display</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/checkin/dual-mini-display"> <PhoneAndroid/><PhoneAndroid/> Dual Mini Display</NavLink></li>
                                        </ul>
                                    </li>
                                    }

                                    
                                    { authUser.role <= 2 && 
                                        <li className="nav-item text-center">
                                            <NavLink className="nav-link" aria-current="page" to="/report"> <ListAlt/> Report </NavLink>
                                        </li>
                                    }

                                    
                                    {/* authUser.role <= 2 && 
                                        <li className="nav-item text-center">
                                            <NavLink className="nav-link" aria-current="page" to="/checkin/display"> <Store/> Counter </NavLink>
                                        </li>
                                    */}

                                    {/*
                                    <li className="nav-item text-center">
                                        <NavLink className="nav-link" aria-current="page" to="/appointment/queue"> <CalendarMonthOutlined /> Appointments</NavLink>
                                    </li>
                                    */}

                                    { authUser.role <= 3 && 
                                        <li className="nav-item text-center">
                                            <NavLink className="nav-link" aria-current="page" to="/checkin/queue"> <FollowTheSigns/> Tokens</NavLink>
                                        </li>
                                    }

                                    

                                    {  (authUser.role === 1 || authUser.role === 5) && 
                                        <li className="nav-item text-center">
                                            <NavLink className="nav-link" aria-current="page" to="/kiosk/portal/1"> <Dvr/> Kiosk</NavLink>
                                        </li>
                                    }

                                    { authUser.role <= 2 && 
                                        <li className="nav-item text-center">
                                            <NavLink className="nav-link" aria-current="page" to="/queue/list"> <TransferWithinAStation /> Queues</NavLink>
                                        </li>
                                    }

                                    
                                </>}
                        </ul>
                        
                        <ul className="navbar-nav">
                            { authUser.role <= 2 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    <li><NavLink className="dropdown-item" to="/master/list"><Label /> Status Master</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/business/details"><WorkOutlined/> Business Details</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>
                                </ul>
                            </li>
                            }



                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    {/*<li><a className="dropdown-item" href="/auth/profile">My Profile</a></li> */ }
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        }
        </>
    )
}
