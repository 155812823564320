import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

export default function Logout() {
  const navigate = useNavigate()
  useEffect(()=>{
    localStorage.clear();
    window.location.reload();
  },[navigate])
  return (
    <div className="text-center"><CircularProgress/></div>
  )
}
