import React, { useEffect, useState } from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog';
import InlineImage from '../../../components/InlineEdit/InlineImage';
import withAlert from '../../../hoc/withAlert'
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import NewsForm from '../components/NewsForm';
import newsServices from '../newsServices';


const NewsListPage = ({AppAlert}) => {

    const [newsList,setNewsList] = useState(null);
    
    const getNewsList = () =>{
        newsServices.get().then((res)=>{        
            setNewsList(res.data);
        }).catch(error=>{
        })
    }

    const [masters,getMasterLabel] = useSystemMasterList(["news-type"]);

    useEffect(()=>{
        getNewsList();
    },[]);


    const remove = (id) => {
        newsServices.remove(id).then(res=>{
            AppAlert(res.msg)
            getNewsList();
        }).catch(error=>{
            AppAlert(error.msg);
        })

    }

    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-12" >
                      <span className="float-end">{masters && <NewsForm masters={masters} AppAlert={AppAlert} refreshList={getNewsList} /> }</span> 
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        { masters &&
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Right Side Ads</h3>
                                </div>
                                {newsList &&
                                    newsList.map(news=>{
                                        if(news.newsType===1){
                                            return (
                                            <div key={news.id} className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        {news.title}
                                                        <span className="float-end">
                                                            {masters && <NewsForm data={news} masters={masters} AppAlert={AppAlert} refreshList={getNewsList} /> }
                                                            <ConfirmDialog id={news.id} confirm={remove} >Are you sure you want to remove this ad</ConfirmDialog>
                                                        </span> 
                                                        </div>
                                                    <div className="card-body">
                                                        <InlineImage editId={news.id} name="image" value={news.image} handleInlineChange={newsServices.updateInline}  />
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                        
                                    }
                                        
                                    )
                                }

                                <div className="col-md-12 pt-2">
                                    <hr />
                                    <h3>Bottom Ads</h3>
                                </div>
                                {newsList &&
                                    newsList.map(news=>{
                                        if(news.newsType===2){
                                            return (
                                            <div key={news.id} className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        {news.title}
                                                        <span className="float-end">
                                                            {masters && <NewsForm data={news} masters={masters} AppAlert={AppAlert} refreshList={getNewsList} /> }
                                                            <ConfirmDialog id={news.id} confirm={remove} >Are you sure you want to remove this ad</ConfirmDialog>
                                                        </span> 
                                                        </div>
                                                    <div className="card-body">
                                                        <p>{news.details}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                    }
                                    )
                                }
                                
                            </div>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(NewsListPage);